import * as React from 'react'
import update from 'immutability-helper'
import { NewModal, NewCheckbox, CallPartition, FlowCutOff, CallPartitionResource, SvgIcon, Input, CallLogo, Select, NewDatePicker, NewTabs, ElementValue, FlowCall, NewMultiSelect, ConfirmAction, NotificationType, ADMIN_PORTAL_ROLES, ROLES } from 'prace-common-components'
import { $ModalContainer, $Button, $Grid, $GridContainer, $CallId, $CallIdLabel, $InputFileHidden, $DeleteWrapper, $LogoContainer, $NotificationClone } from './styles'
import Grid from '@material-ui/core/Grid'
import { EditCallModalProps } from './types'
import { EditTimeFrames } from '../EditTimeFrames'
import { EditPartitions } from '../EditPartitions'
import { CDN_AVATARS, RoleNameItems } from 'constants/global'
import { useAppDispatch } from 'store/hooks'
import { useSearchPartitionsQuery } from 'store/api/partitions'
import { useDeleteCallLogoMutation, useUploadCallLogoMutation } from 'store/api/calls'

const FilteredRoleNameItems = RoleNameItems
  .filter((item) => ![...ADMIN_PORTAL_ROLES, ROLES.SUPER_ADMIN, ROLES.USER].includes(item.value))

//TODO: User will be able to create types in the future
/* These types cannot have the characters '-' or '_' */
const callTypes = [
  { label: 'BEN', value: 'BEN' },
  { label: 'DEV', value: 'DEV' },
  { label: 'PA', value: 'PA' },
  { label: 'RA', value: 'RA' },
  { label: 'REG', value: 'REG' },
  { label: 'EXT', value: 'EXT' },
  { label: 'AI', value: 'AI' },
]

const emptyCall = {
  id: 0,
  uid: '',
  logo: '',
  title: '',
  description: '',
  info: '',
  allowedToDownload: [],
  unconditionalVisibility: [],
  resourcesAllocation: { start: null, end: null },
  cutoffs: [],
  published: false,
}

const emptyResource = {
  id: 0, fixedAllocation: false, fixed: undefined, min: undefined, max: undefined, partition: { id: 0, name: '' }, cutoffs: [],
}

export const EditCallModal: React.FC<EditCallModalProps> = ({
  call = emptyCall, handleUpdateCall, modalTitle, onClose, cloning,
}) => {
  const dispatch = useAppDispatch()
  const [tab, setTab] = React.useState<number>(0)
  const [openNotifications, setOpenNotifications] = React.useState<boolean>(false)
  const [cloneNotifications, setCloneNotifications] = React.useState<boolean>(false)
  const [resourceMapping, setResourceMapping] = React.useState<any>({}) //TODO: Handle any
  const uploadRef = React.useRef<HTMLInputElement>(null)
  const [displayError, setDisplayError] = React.useState<boolean>(false)
  const [notice, setNotice] = React.useState<boolean>(!call.cutoffs?.length)
  const [uploadLogo] = useUploadCallLogoMutation()
  const [deleteLogo] = useDeleteCallLogoMutation()
  const { data: partitionsData } = useSearchPartitionsQuery({ search: '', page: 1 })
  const editingCall = !!call.uid
  const callUid = call.uid.split('-')
  const uidWithType = callUid.length > 2
  const partitionsArray = partitionsData?.partitions || []
  const [callState, setCallState] = React.useState({
    uid: call.uid,
    logo: call.logo,
    title: call.title,
    cutoffs: call.cutoffs,
    description: call.description,
    info: call.info,
    allowedToDownload: call.allowedToDownload,
    unconditionalVisibility: call.unconditionalVisibility,
    resources: [emptyResource] as CallPartitionResource[],
    context: callUid[0] || '',
    type: uidWithType ? callUid[1] : '',
    year: uidWithType ? (callUid[2] ? callUid[2] : '') : (callUid[1] ? callUid[1] : ''),
    resourcesAllocation: call.resourcesAllocation || { start: null, end: null },
  })

  React.useEffect(() => {
    let allResources: CallPartitionResource[] = []
    call.cutoffs.forEach((cutOff) => {
      const resources = cutOff.resources.map((resource) => ({...resource, cutoff: cutOff.id}))
      allResources = [...allResources, ...resources]
    })
    const newResources: CallPartitionResource[] = []
    let verifiedResources: number[] = []
    /* Resource id mapping used to communicate with BE */
    let resourceMapping: any = {} //TODO: Handle any
    allResources.forEach((resource: CallPartitionResource) => {
      if(!verifiedResources.includes(resource.id)) {
        // Get all other resources with same params (but different cutoffs)
        const similarResources = allResources.filter((r) =>
          r.partition.id == resource.partition.id && r.offer == resource.offer &&
        r.min == resource.min && r.max == resource.max && r.fixed == resource.fixed)
        //r.cutoff != resource.cutoff
        verifiedResources = [...verifiedResources, ...similarResources.map((r) => r.id)]
        const cutoffs = similarResources.map((r) => String(r.cutoff))
        newResources.push({...resource, cutoffs, cutoff: undefined})
        const prevResourceMappingPartition = resourceMapping[resource.partition.id] || []
        resourceMapping = {
          ...resourceMapping,
          [resource.partition.id]: [
            ...prevResourceMappingPartition,
            ...similarResources.map((r) => ({id: r.id, cutoff: r.cutoff})),
          ],
        }
      }
    })
    setResourceMapping(resourceMapping)
    setCallState((prevState) => ({
      ...prevState,
      resources: newResources?.length ? newResources : [emptyResource] as CallPartitionResource[],
    }))
  }, [call.cutoffs])

  const { context, type, year, resourcesAllocation, resources, cutoffs, title, description, info } = callState
  const { start, end } = resourcesAllocation

  const tabs = [{ id: 0, label: 'General Settings' }, { id: 1, label: 'Cut-Offs' }]
  if(editingCall || cloning) tabs.push({ id: 2, label: 'Partitions' })

  const handleLogoClick = () => {
    if (uploadRef.current) uploadRef.current.click()
  }

  const handleUploadChange = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const file = target.files ? target.files[0] : null
    if (file && call) {
      try {
        const data = new FormData()
        data.append('file', file)
        const { filename } = await uploadLogo({ id: (call as FlowCall).id, data }).unwrap()
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Logo uploaded' } })
        setCallState((prevState) => update(prevState, {
          logo: { $set: filename },
        }))
      } catch (err) {
        console.log(err)
      }
    }
  }

  const handleLogoRemove = async () => {
    try {
      await deleteLogo((call as FlowCall).id).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Logo deleted' } })
      setCallState((prevState) => update(prevState, {
        logo: { $set: null },
      }))
    } catch (err) {
      console.log(err)
    }
  }

  const onChangeCutOffValue = (name: string, value: ElementValue | FlowCutOff[], idx: number) => {
    setNotice(!(value as FlowCutOff[]).length)
    setCallState((prevState) => {
      const cutoffs = prevState.cutoffs.length ? prevState.cutoffs : [{ title: '', start: null }]
      const cutOff = cutoffs[idx]
      const newResources = prevState.resources?.map(
        (resource: CallPartitionResource) => (
          {...resource, cutoffs: resource.cutoffs?.includes((cutOff?.title || '') as string) ?
            resource.cutoffs?.map((c: string) => (c === (cutOff?.title || '') ? value : c)) : resource.cutoffs})) || []
      const newCutOffs = update(cutoffs, {
        [idx]: { [name]: { $set: value }},
      }) as FlowCutOff[]
      return update(prevState, {
        cutoffs: { $set: newCutOffs },
        resources: { $set: newResources as CallPartitionResource[] },
      })})
  }

  const onDeleteCutOff = (idx: number) => {
    setCallState((prevState) => {
      const cutOff = prevState.cutoffs[idx]
      const newCutoffs = prevState.cutoffs.filter((_, index: number) => index !== idx)
      const newResources = prevState.resources.map(
        (resource: CallPartitionResource) => (
          {...resource, cutoffs: resource.cutoffs?.filter((c: string) => c !== cutOff.title)}))
      const filteredResources = newResources.filter((resource: CallPartitionResource) => !!resource.cutoffs?.length)
      return update(prevState, {
        cutoffs: { $set: newCutoffs },
        resources: { $set: filteredResources },
      })})
  }

  const onChange = (name: string, value: ElementValue | FlowCutOff[]) => {
    if(name === 'cutoffs') {
      setNotice(!(value as FlowCutOff[]).length)
      setCallState((prevState) => update(prevState, {
        cutoffs: { $set: value as FlowCutOff[] },
      }))
    } else {
      let filteredValue = value
      /* Prevent user from using '-' and '_' */
      if(name === 'context' || name === 'year') filteredValue = (value as string).replace(/-/g, '').replace(/_/g, '')
      setCallState((prevState) => update(prevState, {
        [name]: { $set: filteredValue },
      }))
    }
  }

  const onChangeAllocation = (name: string, value: ElementValue) => {
    setCallState((prevState) =>  update(prevState, {
      resourcesAllocation: {
        [name]: { $set: value },
      }}))
  }

  React.useEffect(() => {
    const withType = type ? `-${type}` : ''
    const withYear = year ? `-${year}` : ''
    setCallState((prevState) => update(prevState, {
      uid: { $set: `${context}${withType}${withYear}` },
    }))
  }, [context, type, year])

  const onSaveCallSettings = () => {

    const { context, type, year, cutoffs, resources, ...newCall } = callState

    if(newCall.uid.length < 5) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Call UID must have atleast 5 characters' } })
      return
    }
    if(!context || !callState.title || !callState.description || !cutoffs.length) {
      setDisplayError(true)
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Please fill all required data' } })
      return
    }
    const { start, end } = callState.resourcesAllocation
    if(start && end && (new Date(start).getTime() > new Date(end).getTime())) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'End allocation date is not after the start date' } })
      return
    }

    /* If we are cloning a call, uid must change */
    if(cloning && (callState.uid === call.uid)) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Change the Call Id before duplicating' } })
      return
    }

    // Remove Blank partition resources
    const filledResources = (resources as CallPartitionResource[])
      .filter((resource: CallPartitionResource) => !!resource.partition.id)

    const resourcesWithCutoffs = (filledResources as CallPartitionResource[])
      .filter((resource: CallPartitionResource) => !!resource.cutoffs?.length)
    if(filledResources.length !== resourcesWithCutoffs.length) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Allocation resources must have associated cutoffs' } })
      return
    }

    /* Validate min, max and offer values */
    if(resourcesWithCutoffs.some((r) => (
      (((r.min && r.max) && (r.min > r.max)) ||
        ((r.offer && r.max) && (r.max > r.offer)) ||
          ((r.offer && r.min) && (r.min > r.offer))
      ) || (r.fixed && r.offer) && (r.fixed > r.offer)
    ))) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'There are cut-offs with invalid combinations of min, max and offer' } })
      return
    }

    /* Retrieve the original ids using the resourceMapping */
    const newCutoffs = cutoffs.map((cutoff) => {
      const cutoffResources = resourcesWithCutoffs.filter(
        (resource) => resource.cutoffs?.includes(String(cutoff.id)) || resource.cutoffs?.includes(cutoff?.title || ''))
      const resources = cutoffResources.map((resource) => ({
        ...resource,
        id: resourceMapping[resource.partition.id]?.find((r: { cutoff: number }) => r.cutoff === cutoff.id)?.id,
        cutoffs: undefined,
      }))
      return {
        ...cutoff,
        resources,
      }
    })

    // Remove Blank cutoffs
    const filledCutoffs = newCutoffs.map((cutoff, order: number) => ({ ...cutoff, order }))
      .filter((cutOff) => (cutOff.title || cutOff.start || cutOff.end))
    let invalid = false
    filledCutoffs.forEach((cutOff) => {
      const cutOffNames = filledCutoffs.filter((filterCutOff) => filterCutOff.title === cutOff.title)
      if(cutOffNames.length > 1) {
        dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'CutOffs must have unique names' } })
        invalid = true
      }
      if(!cutOff.title || !cutOff.start) {
        dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'CutOffs must have a start date and name' } })
        invalid = true
      }
    })

    if(invalid) {
      return
    } else {
      handleUpdateCall({...newCall, cutoffs: filledCutoffs} as FlowCall, cloneNotifications)
    }
  }

  const onChangePartition = (oldPartition: CallPartition, partition: CallPartition) => {
    setCallState((prevState) => {
      const resources: CallPartitionResource[] = prevState.resources.map((resource: CallPartitionResource) => {
        if(resource.partition?.id === oldPartition.id)
          return { ...resource, partition } as CallPartitionResource
        else return resource as CallPartitionResource
      })
      return update(prevState, {
        resources: {
          $set: resources,
        }})
    })
  }

  const onChangeResourceAllocation = (resourceId: number) =>
    (name: string, value: Nullable<ElementValue>) => {
      if(name === 'fixedAllocation') {
        setCallState((prevState) => {
          const resourceIdx = (prevState.resources as CallPartitionResource[])
            .findIndex((resource: CallPartitionResource) => resource.id == resourceId)
          return update(prevState, {
            resources: {
              [resourceIdx] : {
                min: { $set: null },
                max: { $set: null },
                offer: { $set: null },
                fixed: { $set: null },
                fixedAllocation: { $set: value as boolean },
              }}})
        })
      } else {
        setCallState((prevState) => {
          const resourceIdx = (prevState.resources as CallPartitionResource[])
            .findIndex((resource: CallPartitionResource) => resource.id == resourceId)
          if(name === 'cutoffs') {
            const partitionId = prevState.resources[resourceIdx].partition.id
            let error = false
            prevState.resources.forEach((resource: CallPartitionResource) => {
              if((resource.partition.id == partitionId) && (resource.id != resourceId) &&
                ((value as string[]).some((val) => resource.cutoffs?.includes(val)))) {
                dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Cannot have resource allocations with equal cutoffs for the same partition' } })
                error =true
              }
            })
            if(error) return prevState
          }
          return update(prevState, {
            resources: {
              [resourceIdx] : {
                [name]: { $set: name === 'cutoffs' ? value : Number(value) },
              }}})
        })
      }
    }

  const onAddPartition = () => {
    setCallState((prevState) => {
      const highestId = Math.max(...prevState.resources.map((resource) => resource.id))
      return update(prevState, {
        resources: { $push: [{...emptyResource, id: highestId + 1} as CallPartitionResource] },
      })})
  }

  const onAddAllocation = (partition: CallPartition) => {
    setCallState((prevState) => {
      const highestId = Math.max(...prevState.resources.map((resource) => resource.id))
      return update(prevState, {
        resources: { $push: [{ ...emptyResource, id: highestId + 1, partition } as CallPartitionResource]},
      })})
  }

  const onDeletePartition = (partitionId: CallPartition['id']) => {
    setCallState((prevState) => {
      const resources = prevState.resources
        .filter((resource: CallPartitionResource) => resource.partition.id !== partitionId)
      if(!resources.length) return prevState
      return update(prevState, {
        resources: { $set: resources },
      })
    })
  }

  const onDeleteAllocation = (resourceId: number) => {
    setCallState((prevState) => {
      const resourceIdx = prevState.resources.findIndex((resource: CallPartitionResource) => resource.id == resourceId)
      return update(prevState, {
        resources: { $splice: [[resourceIdx, 1]] },
      })})
  }

  return <>
    <NewModal open noTopMargin alternateTitle title={modalTitle} onClose={onClose} notice={notice ? 'This call needs at least one cut-off' : undefined}>
      <$ModalContainer>
        <NewTabs tabs={tabs} value={tab} onChange={(t: number) => setTab(t)} />
        {tab == 0 ?
          <Grid container spacing={2}>
            <Grid item xs>
              <$CallIdLabel shrink>
                Call ID
              </$CallIdLabel>
              <$CallId>
                {callState.uid}
              </$CallId>
              <$GridContainer container alignItems='center' direction='row'>
                <Grid item xs>
                  <Input title='Context' name='context' required error={displayError && !context} value={context} hideItalic onChange={onChange} />
                </Grid>
                <Grid item xs>
                  <Select title='Type' name='type' items={callTypes} value={type} onChange={onChange} />
                </Grid>
                <Grid item xs>
                  <Input allowFormat={false} title='Year' name='year' value={year} hideItalic onChange={onChange} />
                </Grid>
              </$GridContainer>
              <$GridContainer container>
                {editingCall &&
                  <Grid item xs={4}>
                    <$LogoContainer>
                      <CallLogo
                        upload
                        logo={callState.logo ? `${CDN_AVATARS}/${callState.logo}` : undefined}
                        title={callState.uid}
                        onLogoClick={handleLogoClick}
                      />
                      {callState.logo &&
                        <ConfirmAction
                          deletion
                          title='Delete Call image'
                          onConfirm={handleLogoRemove}
                        >
                          <$DeleteWrapper>
                            <SvgIcon clickable name='close' size={16} />
                          </$DeleteWrapper>
                        </ConfirmAction>
                      }
                    </$LogoContainer>
                  </Grid>
                }
                <Grid item xs>
                  <Input title='Call Title' name='title' required error={displayError && !title} value={title} hideItalic onChange={onChange} />
                </Grid>
                <Grid item xs={12}>
                  <Input title='Call Description' name='description' required error={displayError && !description} value={description} onChange={onChange} multiline minRows={7} type='text' hideItalic />
                </Grid>
              </$GridContainer>
            </Grid>
            <$Grid container item xs justifyContent='flex-end' direction='column'>
              <$Grid container>
                <Grid item xs={12}>
                  <NewDatePicker  title='Start Allocation Date' name='start' value={start} onChange={onChangeAllocation} />
                </Grid>
                <Grid item xs={12}>
                  <NewDatePicker min={start ? new Date(start) : undefined} error={!!(start && end && (new Date(start).getTime() > new Date(end).getTime()))} title='End Allocation Date' name='end' value={end} onChange={onChangeAllocation} />
                </Grid>
                <Grid item xs={12}>
                  <NewMultiSelect
                    items={FilteredRoleNameItems}
                    hideItalic
                    name='allowedToDownload'
                    title='Roles allowed to download file bundle'
                    /* description={`Select roles this ${type} should be visible to`} */
                    value={callState.allowedToDownload}
                    onChange={(name: string, value: string[]) => onChange(name, value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NewMultiSelect
                    items={FilteredRoleNameItems}
                    hideItalic
                    name='unconditionalVisibility'
                    title='Roles allowed to see all the applications listed'
                    value={callState.unconditionalVisibility}
                    onChange={(name: string, value: string[]) => onChange(name, value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input title='Call Process Text' name='info' value={info} onChange={onChange} multiline minRows={5} type='text' hideItalic />
                </Grid>
              </$Grid>
              <Grid container item xs justifyContent='flex-end' direction='column'>
                <$Button onClick={cloning ? () => setOpenNotifications(true) : onSaveCallSettings}>
                  {editingCall ? 'Save changes' : 'Create Call'}
                </$Button>
              </Grid>
            </$Grid>
          </Grid>
          :
          tab == 1 ?
            <EditTimeFrames
              published={call?.published}
              cutoffs={cutoffs}
              onSaveCutoffs={cloning ? () => setOpenNotifications(true) : onSaveCallSettings}
              onChangeCutOffValue={onChangeCutOffValue}
              onDeleteCutOff={onDeleteCutOff}
              onChange={(cutoffs) => onChange('cutoffs', cutoffs)}
              btnText={editingCall ? 'Save changes' : 'Create Call'}
            />
            :
            <EditPartitions
              cutoffs={cutoffs}
              partitions={partitionsArray}
              resources={resources as CallPartitionResource[]}
              onAddAllocation={onAddAllocation}
              onAddPartition={onAddPartition}
              onDeleteAllocation={onDeleteAllocation}
              onDeletePartition={onDeletePartition}
              onChangePartition={onChangePartition}
              onChangeAllocation={onChangeResourceAllocation}
              onSave={cloning ? () => setOpenNotifications(true) : onSaveCallSettings}
            />
        }
      </$ModalContainer>
    </NewModal>
    {openNotifications &&
      <NewModal open small noTopMargin alternateTitle title='Duplicate Email Templates' onClose={() => setOpenNotifications(false)}>
        <>
          <$NotificationClone>
            <NewCheckbox name='cloneNotifications' value={cloneNotifications} hideItalic onChange={(_, value: boolean) => setCloneNotifications(value)} title='Do Template duplication validating all notify actions?' />
          </$NotificationClone>
          <Grid container>
            <$Button onClick={onSaveCallSettings}>
              Clone Call
            </$Button>
          </Grid>
        </>
      </NewModal>
    }
    <$InputFileHidden
      ref={uploadRef}
      type='file'
      accept='.jpg, .png, .jpeg'
      onChange={handleUploadChange}
    />
  </>
}
