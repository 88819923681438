import React, { useCallback, useEffect, useRef, useState }  from 'react'
import { useParams } from 'react-router-dom'
import { User, $Chip, Column, DataRow, downloadFile, EXPORT, ExportableTable, FILTER_TYPE, NestedMenu, NewButton, NotificationType, Search, Title, USER_STATUS, capitalizeFirstChar } from 'prace-common-components'
import { $ActionBox, $Paper, $Tags, $UserClick, $Users, $UserText } from './styles'
import { UserDetails } from './UserDetails'
import { UserTableColumn, UserTableRow } from './types'
import { useSearchUsersMutation, useUpdateUserMutation } from 'store/api/users'
import { SearchUsers } from 'store/api/users/types'
import { useAppDispatch } from 'store/hooks'

const limit = 15

export const Users = () => {
  const dispatch = useAppDispatch()
  const { id = null } = useParams<{ id: string }>()
  const didMount = useRef(false)
  const [usersData, setUsersData] = useState<SearchUsers>({ users: [], count: 0 })
  const [updateUser] = useUpdateUserMutation()
  const [searchUsers] = useSearchUsersMutation()
  const count = usersData?.count || 0
  const [page, setPage] = useState(1)
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [selected, selectUser] = useState<Nullable<User>>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuItems = [
    { id: 0, label: <>CSV (.csv)</> },
    { id: 1, label: <>Excel (.xlsx)</> },
  ]

  React.useEffect(() => { setPage(1) }, [debouncedSearch])

  useEffect(() => {
    const getUsers = async () => {
      try {
        const data = await searchUsers({ search: debouncedSearch || '', page: page - 1, pageSize: limit }).unwrap()
        setUsersData(data)
      } catch (err) {
        console.log(err)
      }
    }
    getUsers().catch((err) => console.log(err))
  }, [searchUsers, debouncedSearch, page])

  useEffect(() => {
    if (id && !didMount.current && usersData?.users.length) {
      selectUser(usersData.users.find((user) => user.id == Number(id)) || null)
      didMount.current = true
    }
  }, [usersData?.users, id])

  useEffect(() => {
    selectUser((prevSelected) => (usersData?.users.find((user) => user.id == prevSelected?.id) || null))
  }, [usersData?.users])

  /** change current table page */
  const handleChangePage = (page: number) => setPage(page)

  const changeUserDetails = async (
    { id, role, userRemarks, tags, status }: Pick<User, 'id' | 'role' | 'userRemarks' | 'tags' | 'status'>) => {
    try {
      await updateUser({ id, role, userRemarks, tags, status }).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'User details have been updated successfully' } })
      setUsersData((prev) => {
        const userIndex = prev.users.findIndex((user) => user.id == id)
        const updatedUser = { ...prev.users[userIndex], role, userRemarks, tags, status }
        return {
          users: [...prev.users.slice(0, userIndex), updatedUser, ...prev.users.slice(userIndex + 1)],
          count: prev.count,
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleSearch = useCallback((val: string) => {
    setDebouncedSearch(val)
    selectUser(null)
  }, [])

  if (!usersData?.users) return null

  const columns: UserTableColumn[] = [
    {
      key: 'id',
      type: FILTER_TYPE.NONE, //TODO: Will have a TEXT filter later
      name: 'ID',
      noSort: true,
      formatter: ({ row: user }) => {
        return (
          <$UserText disabled={user.isBlocked}>
            {user.id}
          </$UserText>
        )
      },
      width: 50,
    },
    {
      key: 'name',
      type: FILTER_TYPE.NONE, //TODO: Will have a TEXT filter later
      name: 'Name',
      noSort: true,
      formatter: ({ row: user }) => {
        return (
          <$UserClick disabled={user.isBlocked} data-simplebar
            onClick={() => selectUser(user)}>
            {user.name}
          </$UserClick>
        )
      },
    },
    {
      key: 'email',
      type: FILTER_TYPE.NONE,
      noSort: true,
      name: 'Email', //TODO: Will have a TEXT filter later
      formatter: ({ row: user }) => {
        return <$UserText disabled={user.isBlocked}>
          {user.email}
        </$UserText>
      },
    },
    {
      formatter: ({ row: user }) => {
        return (
          <$UserText disabled={user.isBlocked}>
            {capitalizeFirstChar(user.role)}
          </$UserText>
        )
      },
      key: 'role', //TODO: Will have a SELECT filter later
      name: 'Role',
      noSort: true,
      type: FILTER_TYPE.NONE,
      width: 150,
    },
    {
      key: 'tags',
      noSort: true,
      type: FILTER_TYPE.NONE,
      name: 'Tags',
      width: 400,
      formatter: ({ row: user }) => {
        const { tags = [] } = user
        return (
          <$Tags container wrap='nowrap'>
            {tags.map((tag: string) => <$Chip key={tag} size='small' colorScheme='secondary' label={tag}/>)}
          </$Tags>
        )
      },
    },
  ]
  
  const mapUsers = (users: User[]): UserTableRow[] => {
    return users.map((user) => {
      return {
        ...user,
        name: `${user.firstName || '-'} ${user.lastName || '-'}`,
        isBlocked: user.status !== USER_STATUS.Active,
      }
    }) as UserTableRow[]
  }

  const rows = mapUsers(usersData.users)

  const exportAll = async () => {
    try {
      const { users = [] } = await searchUsers({ search: debouncedSearch || '', page: 0, pageSize: 999}).unwrap()
      return mapUsers(users) as DataRow[]
    } catch (err) {
      console.log(err)
      return []
    }
  }

  const downloadAllUsersThatAcceptedEuroHPCNotification = async (type: EXPORT) => {
    try {
      const { users = [] } = await searchUsers({ search: debouncedSearch || '', page: 0, pageSize: 999, acceptedEuroHPCNotification: true}).unwrap()
      const header = ['ID', 'Name', 'Email', 'Role', 'Tags']
      const preparedRows = mapUsers(users).map((row) => [
        row.id,
        row.name,
        row.email,
        row.role,
        row.tags.join(','),
      ])

      if (type === EXPORT.CSV) {
        const content = [
          header.join(','),
          ...preparedRows,
        ].join('\n')

        const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' })
        downloadFile('users.csv', blob)
      } else {
        const [{ utils, writeFile }] = await Promise.all([
          import('xlsx'),
        ])

        const wb = utils.book_new()
        const ws = utils.aoa_to_sheet([header, ...preparedRows])
        utils.book_append_sheet(wb, ws, 'Sheet 1')
        writeFile(wb, 'users.xlsx')
      }
    } catch (err) {
      console.log(err)
    }
    
  }

  const handleDownloadClick = (e: React.MouseEvent<any>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleItemClick = async (id: StringNumber) => {
    setAnchorEl(null)
    switch (id) {
      case 0:
        await downloadAllUsersThatAcceptedEuroHPCNotification(EXPORT.CSV)
        break
      case 1:
        await downloadAllUsersThatAcceptedEuroHPCNotification(EXPORT.XLSX)
    }
  }

  return (
    <$Users>
      <$Paper>
        <Title alternate fontSize={22}>Users</Title>
        <$ActionBox>
          <Search
            open
            placeholder='Search users'
            onChange={handleSearch}
          />
          <NewButton
            variant='outlined'
            onClick={handleDownloadClick}
          >Download mailing list</NewButton>
          <NestedMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            items={menuItems}
            handleItemClick={handleItemClick}
          />
        </$ActionBox>
      </$Paper>
      <ExportableTable
        title='Users'
        rows={rows}
        columns={columns as Column[]}
        total={count}
        rowsPerPage={limit}
        page={page}
        noFilterType
        onChangePage={handleChangePage}
        //TODO: Add filters in the future
        //onFilters={onFilters}
        noFilters
        exportAll={exportAll}
      />
      <UserDetails
        user={selected}
        open={!!selected}
        handleClose={() => selectUser(null)}
        onChangeUserDetails={changeUserDetails}
      />
    </$Users>
  )
}
